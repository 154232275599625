// Import styles
@import "@coreui/coreui/scss/coreui.scss";
// Temp fix for reactstrap
@import "@coreui/coreui/scss/_dropdown-menu-right.scss";

// If you want to override variables do it here
@import "variables";

// If you want to add something do it here
@import "custom";

// Typography
@import "fonts";
@import "typography";

// ie fixes
@import "ie-fix";

// TODO: import all pages
@import "./pages/login.scss";
@import "./pages/register.scss";
@import "./pages/devices.scss";
@import "./pages/device-detail.scss";
@import "./pages/bulk-update.scss";

.c-app {
  --primary: $pepper-green !important;
}

.c-sidebar-nav-item:hover {
  background-color: $pepper-green !important;
}

.c-sidebar.c-sidebar-light.c-sidebar-minimized
  .c-sidebar-nav
  > .c-sidebar-nav-dropdown:hover {
  background: $pepper-green !important;
}

.pepper-spinner {
  color: $pepper-green !important;
}

.card-header {
  background-color: #f0f3f5 !important;
}

.admin-dash-model {
  min-width: 600px !important;
  min-height: 400px;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-primary,
.c-sidebar .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle {
  background: $pepper-green !important;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-primary:hover,
.c-sidebar .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle:hover {
  background: $pepper-green !important;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: $pepper-green;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link,
.c-sidebar.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-sidebar-nav-dropdown-toggle {
  background: $pepper-green;
}
