@import "../variables";

.devices__search-input {
	width: 240px !important;
	height: 38px;
	line-height: 38px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
}

.devices__search-action {
	-webkit-border-radius: 0 2px 2px 0 !important;
	-moz-border-radius: 0 2px 2px 0 !important;
	border-radius: 0 2px 2px 0 !important;
}

.devices__action-btn {
	color: $pepper-green;
	cursor: pointer;
}

.devices__action-btn:hover {
	color: $pepper-green;
	text-decoration: underline;
}