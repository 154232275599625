// Variable overrides
$pepper-green: #82B949;


/* ---------------------------------- */
/* 		     General overrides          */
/* ---------------------------------- */
.navbar-toggler:not(:disabled):not(.disabled) {
	outline: 0;
}

.text-pepper-green {
	color: $pepper-green !important;
}

a.text-pepper-green:hover, a.text-pepper-green:focus {
	color: darken($pepper-green, 10%) !important;
}


/* ---------------------------------- */
/* 		    Side bar overrides          */
/* ---------------------------------- */
.sidebar .nav-link.active .nav-icon {
	color: $pepper-green;
}

.sidebar .nav-link:hover .nav-icon {
	color: #fff;
}

.sidebar .nav-link:hover {
	background-color: $pepper-green;
}

.sidebar-minimized .sidebar .nav-item:hover > .nav-link {
	background-color: $pepper-green;
}

.sidebar-minimized .sidebar .nav-link:hover {
	background-color: $pepper-green;
}

/* ---------------------------------- */
/* 		    Breadcrumbs overrides       */
/* ---------------------------------- */
.breadcrumb-item a, .breadcrumb-item a:hover {
	color: $pepper-green;
}