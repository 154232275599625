@font-face {
    font-family: 'Proxima Nova Black';
    src: url('../assets/fonts/ProximaNova-Black.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Bold';
    src: url('../assets/fonts/ProximaNova-Bold.otf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Bold Italic';
    src: url('../assets/fonts/ProximaNova-BoldIt.otf');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'Proxima Nova Extra Bold';
    src: url('../assets/fonts/ProximaNova-Extrabold.otf');
    font-weight: bolder;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova Light';
    src: url('../assets/fonts/ProximaNova-Light.otf');
    font-weight: lighter;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova Light Italic';
    src: url('../assets/fonts/ProximaNova-LightItalic.otf');
    font-weight: lighter;
    font-style: italic;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('../assets/fonts/ProximaNova-Regular.otf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova Italic';
    src: url('../assets/fonts/ProximaNova-RegularItalic.otf');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Proxima Nova Semibold';
    src: url('../assets/fonts/ProximaNova-Semibold.otf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova Semibold';
    src: url('../assets/fonts/ProximaNova-SemiboldItalic.otf');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Proxima Nova Cond Light';
    src: url('../assets/fonts/ProximaNovaCond-Light.otf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova Cond Light';
    src: url('../assets/fonts/ProximaNovaCond-LightIt.otf');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Proxima Nova Cond';
    src: url('../assets/fonts/ProximaNovaCond-Regular.otf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova Cond';
    src: url('../assets/fonts/ProximaNovaCond-RegularIt.otf');
    font-weight: bold;
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Proxima Nova Cond';
    src: url('../assets/fonts/ProximaNovaCond-Semibold.otf');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova Cond';
    src: url('../assets/fonts/ProximaNovaCond-SemiboldIt.otf');
    font-weight: 600;
    font-style: italic;
}
