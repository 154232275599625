
//
// Typography Conventions
//
// The standard formatting for paragraphs is as follows:
// 1024 or 768: H2 Regular
// 375: H4 Regular
//
.primary-h1-reg-text {
  font-size: 24px;
  font-weight: normal;
  font-family: 'Proxima Nova', sans-serif;
}

.primary-h1-light-text {
  font-size: 24px;
  font-weight: normal;
  font-family: 'Proxima Nova Light', sans-serif;
}

.primary-h1-bold-text {
  font-size: 24px;
  font-weight: bold;
  font-family: 'Proxima Nova', sans-serif;
}

.primary-h2-light-text {
  font-size: 20px;
  font-weight: 300;
  font-family: 'Proxima Nova Light', sans-serif;
}

.primary-h2-reg-text {
  font-size: 20px;
  font-weight: normal;
  font-family: 'Proxima Nova', sans-serif;
}

.primary-h2-semibold-text {
  font-size: 20px;
  font-weight: normal;
  font-family: 'Proxima Nova Semibold', sans-serif;
}

.primary-h2-bold-uppercase-text {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Proxima Nova', sans-serif;
}

.primary-h2-bold-text {
  font-size: 20px;
  font-weight: bold;
  font-family: 'Proxima Nova Bold', sans-serif;
}

.primary-h3-reg-text {
  font-size: 18px;
  font-weight: normal;
  font-family: 'Proxima Nova', sans-serif;
}

.primary-h3-light-text {
  font-size: 18px;
  font-weight: normal;
  font-family: 'Proxima Nova Light', sans-serif;
}

.primary-h3-semibold-text {
  font-size: 18px;
  font-weight: normal;
  font-family: 'Proxima Nova Semibold', sans-serif;
}

.primary-h3-bold-text {
  font-size: 18px;
  font-weight: normal;
  font-family: 'Proxima Nova Bold', sans-serif;
}

.primary-h4-reg-text {
  font-size: 16px;
  font-weight: normal;
  font-family: 'Proxima Nova', sans-serif;
}

.primary-h4-bold-uppercase-text {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Proxima Nova', sans-serif;
}

.primary-h4-semibold-text {
  font-size: 16px;
  font-weight: noraml;
  font-family: 'Proxima Nova Semibold', sans-serif;
}

.primary-h4-bold-text {
  font-size: 16px;
  font-weight: bold;
  font-family: 'Proxima Nova', sans-serif;
}

.primary-h4-light-text {
  font-size: 16px;
  font-weight: normal;
  font-family: 'Proxima Nova Light', sans-serif;
}

.primary-h5-reg-text {
  font-size: 14px;
  font-weight: normal;
  font-family: 'Proxima Nova', sans-serif;
}

.primary-h5-semibold-text {
  font-size: 14px;
  font-weight: normal;
  font-family: 'Proxima Nova Semibold', sans-serif;
}

.primary-h5-bold-text {
  font-size: 14px;
  font-weight: bold;
  font-family: 'Proxima Nova', sans-serif;
}

.primary-h6-reg-text {
  font-size: 12px;
  font-weight: normal;
  font-family: 'Proxima Nova', sans-serif;
}

.primary-h6-semibold-text {
  font-size: 12px;
  font-weight: normal;
  font-family: 'Proxima Nova Semibold', sans-serif;
}

.primary-h6-bold-text {
  font-size: 12px;
  font-weight: bold;
  font-family: 'Proxima Nova', sans-serif;
}