@import "../variables";

.login-page-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(lighten($pepper-green, 4%), $pepper-green);
}

.login__box {
	width: 480px;
	height: 520px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	background-color: #fff;
	-webkit-box-shadow: 0 5px 20px -6px rgba(20, 19, 20, 0.5);
	-moz-box-shadow: 0 5px 20px -6px rgba(20, 19, 20, 0.5);
	box-shadow: 0 5px 20px -6px rgba(20, 19, 20, 0.5);
}

.login-branding-label {
	color: #6D6D6D;
	font-size: 14px;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
}

.login-input-label {
	font-size: 14px;
	font-weight: 600;
	font-family: 'Open Sans', sans-serif;
}

.login__loading-container {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
}