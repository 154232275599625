@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "../variables";

.bulk-update__file-input.custom-file {
  height: calc(#{$input-height} + 5px);
}

.bulk-update__file-input {
  .custom-file-input {
    line-height: 1.5;
    padding: 6px 12px;
  }

  .custom-file-input:disabled ~ .custom-file-label {
    background-color: $white;
  }

  .custom-file-input:disabled ~ .custom-file-label::after {
    cursor: default;
    background-color: #d4d4d4;
    border-color: #d4d4d4;
    color: #b1b1b1;
  }

  .custom-file-label {
    height: calc(#{$input-height} + 5px);
  }

  .custom-file-label {
    overflow: hidden;
    white-space: nowrap;
  }

  .custom-file-label::after {
    background-color: $pepper-green;
    border-color: $pepper-green;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    border-color: $pepper-green;
    color: white;
    font-size: 15px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    height: calc(#{$input-height-inner} + 5px);
    padding: 0.425rem 0.75rem;
    -webkit-transition: background-color 0.3s, box-shadow 0.2s;
    -moz-transition: background-color 0.3s, box-shadow 0.2s;
    transition: background-color 0.3s, box-shadow 0.2s;
  }

  .custom-file-label:hover::after {
    border-color: darken($pepper-green, 5%);
    background-color: darken($pepper-green, 5%);
  }

  .custom-file-label:focus::after,
  .custom-file-label:active::after {
    background-color: darken($pepper-green, 5%) !important;
    border-color: darken($pepper-green, 5%) !important;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 185, 73, 0.5) !important;
    box-shadow: 0 0 0 0.2rem rgba(130, 185, 73, 0.5) !important;
  }
}

.file-summary-refresh-btn,
.file-template-btn {
  border-radius: 2px;
}

.file-summary-refresh-btn {
  margin-left: 1em;
}
