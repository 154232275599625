@import "../variables";

.register-page-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(lighten($pepper-green, 4%), $pepper-green);
}

.register__box {
	width: 480px;
	height: 520px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	border: none;
	background-color: #fff;
	-webkit-box-shadow: 0 5px 20px -6px rgba(20, 19, 20, 0.5);
	-moz-box-shadow: 0 5px 20px -6px rgba(20, 19, 20, 0.5);
	box-shadow: 0 5px 20px -6px rgba(20, 19, 20, 0.5);
}

.register-branding-label {
	color: #6D6D6D;
	font-size: 14px;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
}

.register-form-action {
	background-color: $pepper-green;
	border-color: $pepper-green;
	font-size: 14px;
	font-weight: 600;
	font-family: 'Open Sans', sans-serif;
	-webkit-transition: background-color 0.3s, box-shadow 0.2s;
	-moz-transition: background-color 0.3s, box-shadow 0.2s;
	transition: background-color 0.3s, box-shadow 0.2s;
}

.register-form-action:hover {
	border-color: darken($pepper-green, 5%);
	background-color: darken($pepper-green, 5%);
}

.register-form-action:focus, .register-form-action.focus,
.register-form-action:active, .register-form-action.active {
	background-color: darken($pepper-green, 5%) !important;
	border-color: darken($pepper-green, 5%) !important;
	-webkit-box-shadow: 0 0 0 0.2rem rgba(130, 185, 73, 0.5) !important;
	box-shadow: 0 0 0 0.2rem rgba(130, 185, 73, 0.5) !important;
}

.register-form-action.disabled,
.register-form-action.disabled:hover {
	cursor: default;
	background-color: #ccc !important;
	border-color: #ccc !important;
	color: #949494 !important;
}

.register-form-action.disabled:focus, .register-form-action.disabled.focus,
.register-form-action.disabled:active, .register-form-action.disabled.active {
	background-color: darken($pepper-green, 5%) !important;
	border-color: darken($pepper-green, 5%) !important;
	-webkit-box-shadow: 0 0 0 0.2rem rgba(130, 185, 73, 0.5) !important;
	box-shadow: 0 0 0 0.2rem rgba(130, 185, 73, 0.5) !important;
}
