// Here you can add other styles

.pepper-primary-btn {
  background-color: $pepper-green;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  border-color: $pepper-green;
  font-size: 14px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  -webkit-transition: background-color 0.3s, box-shadow 0.2s;
  -moz-transition: background-color 0.3s, box-shadow 0.2s;
  transition: background-color 0.3s, box-shadow 0.2s;
}

.pepper-secondary-btn {
  background-color: transparent;
  color: $pepper-green;
  font-size: 14px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  border: none;
}

.pepper-secondary-danger-btn {
  background-color: transparent;
  color: #E85500;
  font-size: 14px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  border: none;
}

.pepper-primary-btn:hover {
  border-color: darken($pepper-green, 5%);
  background-color: darken($pepper-green, 5%);
}

.pepper-primary-btn:focus,
.pepper-primary-btn.focus,
.pepper-primary-btn:active,
.pepper-primary-btn.active {
  background-color: darken($pepper-green, 5%) !important;
  border-color: darken($pepper-green, 5%) !important;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 185, 73, 0.5) !important;
  box-shadow: 0 0 0 0.2rem rgba(130, 185, 73, 0.5) !important;
}

.pepper-primary-btn.disabled,
.pepper-primary-btn.disabled:hover {
  cursor: default;
  background-color: #ccc !important;
  border-color: #ccc !important;
  color: #949494 !important;
}

.pepper-primary-btn.disabled:focus,
.pepper-primary-btn.disabled.focus,
.pepper-primary-btn.disabled:active,
.pepper-primary-btn.disabled.active {
  background-color: darken($pepper-green, 5%) !important;
  border-color: darken($pepper-green, 5%) !important;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 185, 73, 0.5) !important;
  box-shadow: 0 0 0 0.2rem rgba(130, 185, 73, 0.5) !important;
}

.pepper-primary-btn.btn-sm {
  font-size: 12px;
}


.pepper-btn-danger {
  color: #fff;
  background-color: #E85500;
  border-color: #E85500;
}
.pepper-btn-danger:hover {
  color: #fff;
  background-color: #f64846;
  border-color: #f63c3a;
}
.pepper-btn-danger:focus,
.pepper-btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
}
.pepper-btn-danger.disabled,
.pepper-btn-danger:disabled {
  color: #fff;
  background-color: #805252;
  border-color: #805252;
}
.pepper-btn-danger:not(:disabled):not(.disabled):active,
.pepper-btn-danger:not(:disabled):not(.disabled).active,
.show > .pepper-btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #f63c3a;
  border-color: #f5302e;
}
.pepper-btn-danger:not(:disabled):not(.disabled):active:focus,
.pepper-btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .pepper-btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
}

.pepper-primary-modal-header {
  background-color: $pepper-green;
  color: $white;

  button.close,
  button.close:hover,
  button.close:active,
  button.close:focus {
    color: $white !important;
  }
}

.pepper-error-modal-header {
  background-color: #c20808;
  color: $white;
}

// General overrides
p {
  margin-bottom: 0;
}

.bg-light {
  background-color: #f6f6f6 !important;
}

// Table override styles
.table th,
.table td {
  border-top: none;
}

.table thead th {
  border-bottom: 1px solid #c8ced3;
}

.form-control:focus {
  color: #5c6873;
  background-color: #fff;
  border-color: darken($pepper-green, 5%) !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(130, 185, 73, 0.5) !important;
}

.dropdown-item {
  outline: none !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: $pepper-green !important;
  border-color: $pepper-green !important;
  color: #fff !important;
}

// General
.device-selected-border {
  border: 1px solid transparent !important;
  box-shadow: 0 0 0 2px $pepper-green;
}

// Checkbox
.checkbox--selected {
  border-color: $pepper-green !important;
  background-color: $pepper-green;
}
